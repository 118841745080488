import React from 'react';
import { useForm } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css'; // Import styles for the text editor
import ReactQuill from 'react-quill';
import UseAxiosPublic from '../../../hook/UseAxiosPublic';
import UseAxiosSecure from '../../../hook/UseAxiosSecure';

const image_hosting_key = '861c499f7e35f3289a840a01f341ab63';
const image_hosting_api = `https://api.imgbb.com/1/upload?key=${image_hosting_key}`;

const Post = () => {
    const axiosPublic = UseAxiosPublic();
    const axiosSecure = UseAxiosSecure();
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);

        // Create FormData to upload the image
        const formData = new FormData();
        formData.append('image', data.image[0]); // The 'image' field is required by imgBB API

        axiosPublic
    .post(image_hosting_api, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then((res) => {
        console.log(res.data); // Log response to see API feedback
        if (res.data.success) {
            const info = {
                title: data.title,
                publish_date: data.publish_date,
                publish_by: data.publish_by,
                like_count: data.like_count,
                category: data.category,
                image: res.data.data.display_url, // Uploaded image URL
                details: data.details,
                keywords: data.keywords,
                meta_description: data.meta_description,
            };

            axiosSecure
                .post('/blogs', info)
                .then((response) => {
                    console.log(response.data);
                    alert('Blog Posted Successfully');
                })
                .catch((err) => {
                    console.error('Error saving blog post:', err.response || err);
                    alert('Failed to save the blog post.');
                });
        } else {
            console.error('ImgBB API Error:', res.data);
            alert('Failed to upload image: ' + res.data.error.message);
        }
    })
    .catch((err) => {
        console.error('Image Upload Error:', err.response || err);
        alert('Failed to upload the image. Check the console for more details.');
    });

    };

    React.useEffect(() => {
        register('details');
    }, [register]);

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }], // Text color and background color
            ['clean'],
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color', 'background', // Formats for text color and background color
    ];

    return (
        <div className='py-8'>
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
                <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Title Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Title</label>
                        <input
                            type="text"
                            {...register('title', { required: true })}
                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 py-2 rounded-md"
                        />
                        {errors.title && <p className="text-red-600 text-sm">Title is required.</p>}
                    </div>

                    {/* Publish Date Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Publish Date</label>
                        <input
                            type="date"
                            {...register('publish_date', { required: true })}
                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 py-2 rounded-md"
                        />
                        {errors.publish_date && <p className="text-red-600 text-sm">Publish date is required.</p>}
                    </div>

                    {/* Publish By Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Publish By</label>
                        <input
                            type="text"
                            {...register('publish_by', { required: true })}
                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 py-2 rounded-md"
                        />
                        {errors.publish_by && <p className="text-red-600 text-sm">Publish by is required.</p>}
                    </div>

                    {/* Like Count Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Like Count</label>
                        <input
                            type="number"
                            {...register('like_count', { required: true })}
                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 py-2 rounded-md"
                        />
                        {errors.like_count && <p className="text-red-600 text-sm">Like count is required.</p>}
                    </div>

                    {/* Category Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Category</label>
                        <input
                            type="text"
                            {...register('category', { required: true })}
                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 py-2 rounded-md"
                        />
                        {errors.category && <p className="text-red-600 text-sm">Category is required.</p>}
                    </div>

                    {/* Image Upload Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Image</label>
                        <input
                            type="file"
                            {...register('image', { required: true })}
                            className="mt-1 block w-full text-sm text-gray-500"
                        />
                        {errors.image && <p className="text-red-600 text-sm">Image is required.</p>}
                    </div>

                    {/* Keywords Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Keywords</label>
                        <input
                            type="text"
                            {...register('keywords', { required: true })}
                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 py-2 rounded-md"
                        />
                        {errors.keywords && <p className="text-red-600 text-sm">Keywords are required.</p>}
                    </div>

                    {/* Meta Description Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Meta Description</label>
                        <textarea
                            {...register('meta_description', { required: true })}
                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.meta_description && <p className="text-red-600 text-sm">Meta description is required.</p>}
                    </div>

                    {/* Details Field with ReactQuill */}
                    <div className="col-span-1 md:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Details</label>
                        <ReactQuill
                            value={getValues('details')}
                            onChange={(content) => setValue('details', content)}
                            modules={modules}
                            formats={formats}
                            className="mt-1"
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="col-span-1 md:col-span-2">
                        <button type="submit" className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Post;
