import React from 'react';
import bgImg from '../../assets/banner-2.jpg';
import spring from '../../assets/1-light.png';
import './styles.css';
import ServiceModal from '../Modal/ServiceModal';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FaHandPointLeft } from 'react-icons/fa';

const Services = () => {
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedService, setSelectedService] = React.useState(null);

    const { data: services = [] } = useQuery({
        queryKey: ['services'],
        queryFn: async () => {
            const res = await axios.get('https://portbackend-chi.vercel.app/services');
            return res.data;
        },
    });

    const handleOpenModal = (service) => {
        setSelectedService(service);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedService(null);
    };

    return (
        <div>
            <div className="relative max-w-[1616px] mx-auto transform px-14"
                style={{
                    backgroundImage: `url(${bgImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}>
                {/* Black Overlay */}
                <div className="absolute inset-0 bg-black opacity-30"></div>

                <div className="relative z-10">
                    <p className='text-xl font-bold text-gradient text-center py-4'>MY EXPERTISE</p>
                    <p className='underline-offset-2 text-4xl font-bold text-center text-white mb-16'>What I Do</p>
                    <div className='flex flex-col md:flex-col-2 lg:flex-row justify-between gap-10 mx-auto lg:max-w-[1091px]'>
                        {services.map(service => (
                            <div key={service._id} className='text-white lg:w-full break-all'>
                                <img className='w-16 mb-6' src={service.icon} alt='img' />
                                <h2 className='text-xl mb-8 cursor-pointer' onClick={() => handleOpenModal(service)}>
                                    <span className='flex gap-2 items-center'>
                                        {service.title} 
                                        <FaHandPointLeft className='text-2xl hand-animate text-blue-700' />
                                    </span>
                                </h2>
                                <p className='lg:w-full'>{service.details}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex justify-end items-end'>
                <img className='w-40' src={spring} alt='spring' />
            </div>
            {/* Service Modal */}
            {selectedService && (
                <ServiceModal open={openModal} handleClose={handleCloseModal} service={selectedService} />
            )}
        </div>
    );
};

export default Services;
