import axios from 'axios';


const UseAxiosPublic = () => {
    const axiosPublic=axios.create({
        baseURL: 'https://portbackend-chi.vercel.app',
        
    })
    return axiosPublic;
};

export default UseAxiosPublic;