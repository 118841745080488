import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify'; // Ensure this is installed: npm install dompurify

const Blog = () => {
    const [blogs, setBlogs] = useState([]);

    // Function to truncate text at a word limit
    const conCateText = (text, wordsLimit) => {
        const words = text.split(" ");
        if (words.length <= wordsLimit) {
            return text;
        }
        return words.slice(0, wordsLimit).join(" ") + "...";
    };

    useEffect(() => {
        fetch('https://portbackend-chi.vercel.app/blogs')
            .then(res => res.json())
            .then(data => {
                // Sort blogs by publish_date in descending order
                const sortedBlogs = data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date));
                // Get the latest three blogs
                const latestBlogs = sortedBlogs.slice(0, 3);
                setBlogs(latestBlogs);
            });
    }, []);

    return (
        <div className="max-w-6xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto text-white px-4 lg:px-8 mt-20 overflow-x-hidden">
            <div>
                <Helmet>
                    <title>Blogs</title>
                    <meta name="description" content="Latest blog posts from Top Orion" />
                    <meta name="author" content="Top Orion" />
                </Helmet>
            </div>

            <div className="text-center mb-20">
                <p className="text-2xl font-bold">News || Blog</p>
                <p className="text-4xl font-bold">Latest From Blog</p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {blogs.map((blog, index) => (
                    <Card
                        sx={{
                            maxWidth: 345,
                            backgroundColor: '#64748b',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                        key={index}
                    >
                        <div>
                            <CardMedia
                                sx={{ height: 240 }}
                                image={blog.image}
                                title={blog.title}
                            />
                            <CardContent sx={{ backgroundColor: '#64748b', color: 'white', flexGrow: 1 }}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {blog.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(conCateText(blog.details, 10))
                                        }}
                                    />
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <Link to={`/blog/${blog._id}`}>
                                <button className="btn ml-4 mb-4 rounded-sm">Read More</button>
                            </Link>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default Blog;
