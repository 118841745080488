import React, { useMemo, useState } from 'react';
import UseAxiosPublic from '../../../hook/UseAxiosPublic';
import { useQuery } from '@tanstack/react-query';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import blogBg from '../../../assets/blogbgFinal.png';
import UseAuth from '../../../hook/UseAuth';

const News = () => {
    const axiosPublic = UseAxiosPublic();
    const { loading } = UseAuth(); // Get the loading state from AuthContext
    const [searchTerm, setSearchTerm] = React.useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6; // Number of items per page

    const { data: items = [], refetch } = useQuery({
        queryKey: ['items', searchTerm],
        queryFn: async () => {
            const res = await axiosPublic.get(`/blogs?search=${searchTerm}`);
            return res.data;
        },
        enabled: !loading // Only fetch data when not loading
    });

    const totalPages = useMemo(() => Math.ceil(items.length / itemsPerPage), [items.length, itemsPerPage]);

    const paginatedItems = useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        return items.slice(start, start + itemsPerPage);
    }, [items, currentPage, itemsPerPage]);

    const conCateText = (text = "", wordsLimit) => {
        const words = text.split(" ");
        if (words.length <= wordsLimit) {
            return text;
        }
        return words.slice(0, wordsLimit).join(" ") + "...";
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
        refetch();
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
    };

    const categoryCounts = useMemo(() => {
        const counts = items.reduce((acc, item) => {
            const category = item.category || "Others";
            if (!acc[category]) {
                acc[category] = 0;
            }
            acc[category]++;
            return acc;
        }, {});
        counts["All"] = items.length;
        return counts;
    }, [items]);

    const sortedBlogs = useMemo(() => {
        return [...items].sort((a, b) => new Date(b.Publish_date) - new Date(a.Publish_date));
    }, [items]);

    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator while waiting for auth or data
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="relative">
                <img
                    className="w-full h-[380px] object-cover"
                    src={blogBg}
                    alt="custom website development"
                />
                <Link to="/">
                    <button
                        className="absolute top-5 right-5 p-3 w-[150px] text-white font-semibold rounded-lg border-2 border-transparent hover:bg-white hover:text-black transition duration-300 blog-button cursor-pointer"
                    >
                        Home
                    </button>
                </Link>
            </div>

            <h1 className="text-3xl font-bold text-center py-10 underline-offset-2 underline">
                Latest News
            </h1>

            <div className="w-full lg:max-w-7xl mx-auto flex flex-col lg:flex-row gap-6 px-4">
                {/* Blog Cards Section */}
                <div className="flex-1">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {paginatedItems.map((blog, index) => (
                            <Card
                                sx={{
                                    backgroundColor: '#64748b',
                                    color: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                                key={index}
                            >
                                <div>
                                    <CardMedia
                                        sx={{ height: 200 }}
                                        image={blog.image}
                                        title={blog.title}
                                    />
                                    <CardContent
                                        sx={{
                                            backgroundColor: '#64748b',
                                            color: 'white',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Typography gutterBottom variant="h5" component="div">
                                            <p className="md:text-xl">{blog.title}</p>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: conCateText(blog.details, 20),
                                                }}
                                            />
                                        </Typography>
                                    </CardContent>
                                </div>
                                <div>
                                    <Link to={`/blog/${blog._id}`}>
                                        <button className="btn ml-4 mb-4 rounded-sm">Read More</button>
                                    </Link>
                                </div>
                            </Card>
                        ))}
                    </div>

                    {/* Pagination Controls */}
                    <div className="flex justify-center mt-6 space-x-2">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            className="p-2 border rounded"
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={`p-2 border rounded ${
                                    currentPage === index + 1 ? 'bg-blue-500 text-white' : ''
                                }`}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            className="p-2 border rounded"
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>

                {/* Sidebar Section */}
                <div className="lg:w-[250px] bg-white p-4 shadow-md">
                    <div className="mb-6">
                        <p className="text-xl font-semibold mb-2">SEARCH</p>
                        <input
                            type="text"
                            placeholder="Search"
                            onChange={handleSearch}
                            className="border-2 p-2 w-full"
                        />
                    </div>

                    <div className="py-4">
                        <p className="text-lg font-semibold mb-2">CATEGORIES</p>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <p>All</p>
                                <p>({categoryCounts["All"]})</p>
                            </div>
                            {Object.keys(categoryCounts)
                                .filter((category) => category !== "All")
                                .map((category) => (
                                    <div key={category} className="flex justify-between">
                                        <p>{category}</p>
                                        <p>({categoryCounts[category]})</p>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div>
                        <p className="text-xl font-semibold mb-2">RECENT POSTS</p>
                        <div className="space-y-4">
                            {sortedBlogs.slice(0, 3).map((blog) => (
                                <div key={blog._id} className="flex items-center">
                                    <div className="w-20 mr-4">
                                        <img
                                            src={blog.image}
                                            alt={blog.title}
                                            className="object-cover w-full"
                                        />
                                    </div>
                                    <Link to={`/blog/${blog._id}`}>
                                        <p className="text-base font-semibold">{blog.title}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;
