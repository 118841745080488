import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify
import UseAxiosPublic from '../../hook/UseAxiosPublic';
import blogBg from '../../assets/blogbgFinal.png';
import './styles.css';
import Search from '../../components/searchAndCategories/Search';
import { Helmet } from 'react-helmet-async';

const Blogs = () => {
    const { id } = useParams();
    const axiosPublic = UseAxiosPublic();

    const { data: blogs = [] } = useQuery({
        queryKey: ['blogs'],
        queryFn: async () => {
            const res = await axiosPublic.get('/blogs');
            return res.data;
        }
    });

    const specificData = blogs.find(blog => blog._id === id);

    if (!specificData) return <div>Loading...</div>;

    // Add protocol to URLs if missing
    const fixURLs = (htmlContent) => {
        return htmlContent.replace(
            /href="(www\.[\w\-\.]+\.[a-z]{2,})"/g,
            'href="https://$1"'
        );
    };

    // Sanitize and fix URLs in HTML content
    const rawHTML = specificData.details;
    const sanitizedHTML = DOMPurify.sanitize(fixURLs(rawHTML));

    return (
        <div>
            <Helmet>
                <title>{specificData.title || "TopOrion"}</title>
                <meta name="description" content={specificData.meta_description || specificData.description} />
                <meta name="keywords" content={specificData.keywords} />
                <meta name="author" content="Top Orion" />
                <meta property="title" content={specificData.title} />
            </Helmet>

            {/* Blog Header Section */}
            <div>
                <div>
                    <img className="w-full h-[380px] object-cover" src={blogBg} alt="custom website development" />
                </div>
                <Link to="/">
                    <button
                        className="absolute top-5 right-5 p-3 w-[150px] text-white font-semibold rounded-lg border-2 border-transparent hover:bg-white hover:text-black transition duration-300 blog-button cursor-pointer z-20"
                    >
                        Home
                    </button>
                </Link>
            </div>

            {/* Blog Content Section */}
            <h1 className="text-3xl font-bold text-center mt-8 mb-6 underline">Latest Blog</h1>
            <div className="max-w-7xl mx-auto mt-4 flex justify-between gap-8 px-2">
                <div className="max-w-4xl">
                    {/* Blog Image and Metadata */}
                    <div>
                        <img className="object-fill w-full h-[400px]" src={specificData.image} alt={specificData.title} />
                        <p className="text-gray-600">Publish Date: {specificData.publish_date}</p>
                        <p className="text-xl font-bold text-blue-300">{specificData.title}</p>
                    </div>

                    {/* Render the sanitized HTML content */}
                    <div
                        className="prose max-w-none break-words mt-4"
                        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                    />
                </div>

                {/* Sidebar Section */}
                <div className="w-[350px]">
                    <Search />
                </div>
            </div>
        </div>
    );
};

export default Blogs;
