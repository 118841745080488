import React from 'react';
import { MdDashboard } from 'react-icons/md';
import { Link, Outlet } from 'react-router-dom';
import { RiAccountPinCircleFill, RiProjectorFill } from "react-icons/ri";
import { FaUsers } from 'react-icons/fa';

const Dashboard = () => {
    return (
        <div className='bg-slate-300 min-h-screen flex'>
            {/* Sidebar */}
            <div className='md:w-64 bg-gradient-to-t from-slate-700 via-sky-500 to-slate-800 flex-shrink-0'>
                <ul className='space-y-4 text-xl font-bold text-white mt-4'>
                    <li>
                        <Link>
                            <span className='ml-3 flex gap-2'>
                                <MdDashboard className='text-pink-500 font-bold text-2xl' />
                                Dashboard
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <span className='ml-3 flex gap-2'>
                                <RiAccountPinCircleFill className='text-pink-500 font-bold text-2xl' />
                                My Account
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <span className='ml-3 flex gap-2'>
                                <RiProjectorFill className='text-pink-500 font-bold text-2xl' />
                                Project
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <span className='ml-3 flex gap-2'>
                                <FaUsers className='text-pink-500 font-bold text-2xl' />
                                Total Users
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>
            {/* Main Content */}
            <div className='flex-1 bg-slate-600 p-4'>
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;
